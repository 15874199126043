import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
// import MainLayout from '../layouts/main';
import SimpleLayout from '../layouts/simple';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  // Auth
  LoginPage,
  VerifyCodePage,
  // Dashboard: General
  GeneralAppPage,
  GeneralFilePage,
  GeneralBankingPage,
  GeneralEcommercePage,
  GeneralAnalyticsPage,
  BookingListPage,
  // Dashboard: User
  UserListPage,
  UserEditPage,
  UserCardsPage,
  UserCreatePage,
  UserProfilePage,
  UserAccountPage,
  // Dashboard: Profile
  UpdateProfilePage,
  UpdateAboutUs,
  UpdateWelcomeText,
  UpdateChooseUs,
  // Dashboard: Gallery
  UpdateGalleryPage,
  UpdateYouTubeVideos,
  // Dashboard: Advanced Profile
  UpdateExclusiveDeals,
  UpdateHotelInfo,
  UpdateHotelfeature,
  // Dashboard: Billing
  TopupListPage,
  TopupCreatePage,
  // Dashboard: Invoice
  InvoiceListPage,
  InvoiceDetailsPage,
  InvoiceCreatePage,
  InvoiceEditPage,
  // Dashboard: Blog
  BlogPostsPage,
  BlogPostPage,
  BlogNewPostPage,
  // Dashboard: Testimonial
  TestimonialListPage,
  TestimonialCreatePage,
  TestimonialEditPage,
  // Dashboard: Activity
  ActivityListPage,
  ActivityEditPage,
  ActivityCreatePage,
  // Dashboard: Service
  ServiceListPage,
  ServiceEditPage,
  ServiceCreatePage,
  // Dashboard: Offer
  OfferListPage,
  OfferEditPage,
  OfferCreatePage,
  // Dashboard: Accommodation
  AccommodationListPage,
  AccommodationEditPage,
  AccommodationCreatePage,
  // Dashboard: Setting
  UpdateSetting,
  UpdateUploadSettingPage,
  // MR.TRAVELLER
  // Dashboard: Places
  PlacesListPage,
  PlacesEditPage,
  PlacesCreatePage,
  // Dashboard: Package
  PackageListPage,
  PackageEditPage,
  PackageCreatePage,
  // Dashboard: FileManager
  FileManagerPage,
  // Dashboard: App
  ChatPage,
  MailPage,
  CalendarPage,
  KanbanPage,
  //
  BlankPage,
  PermissionDeniedPage,
  //
  Page500,
  Page403,
  Page404,
  // HomePage,
  FaqsPage,
  AboutPage,
  Contact,
  PricingPage,
  PaymentPage,
  ComingSoonPage,
  MaintenancePage,
  //
  ComponentsOverviewPage,
  FoundationColorsPage,
  FoundationTypographyPage,
  FoundationShadowsPage,
  FoundationGridPage,
  FoundationIconsPage,
  //
  MUIAccordionPage,
  MUIAlertPage,
  MUIAutocompletePage,
  MUIAvatarPage,
  MUIBadgePage,
  MUIBreadcrumbsPage,
  MUIButtonsPage,
  MUICheckboxPage,
  MUIChipPage,
  MUIDataGridPage,
  MUIDialogPage,
  MUIListPage,
  MUIMenuPage,
  MUIPaginationPage,
  MUIPickersPage,
  MUIPopoverPage,
  MUIProgressPage,
  MUIRadioButtonsPage,
  MUIRatingPage,
  MUISliderPage,
  MUIStepperPage,
  MUISwitchPage,
  MUITablePage,
  MUITabsPage,
  MUITextFieldPage,
  MUITimelinePage,
  MUITooltipPage,
  MUITransferListPage,
  MUITreesViewPage,
  //
  DemoAnimatePage,
  DemoCarouselsPage,
  DemoChartsPage,
  DemoCopyToClipboardPage,
  DemoEditorPage,
  DemoFormValidationPage,
  DemoImagePage,
  DemoLabelPage,
  DemoLightboxPage,
  DemoMapPage,
  DemoMegaMenuPage,
  DemoMultiLanguagePage,
  DemoNavigationBarPage,
  DemoOrganizationalChartPage,
  DemoScrollbarPage,
  DemoSnackbarPage,
  DemoTextMaxLinePage,
  DemoUploadPage,
  DemoMarkdownPage,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
       
        { path: 'login-unprotected', element: <LoginPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'file', element: <GeneralFilePage /> },
        { path: 'app', element: <GeneralAppPage /> },
        { path: 'ecommerce', element: <GeneralEcommercePage /> },
        { path: 'analytics', element: <GeneralAnalyticsPage /> },
        { path: 'booking', element: <BookingListPage /> },
        { path: 'banking', element: <GeneralBankingPage /> },
        { path: 'file', element: <GeneralFilePage /> },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/list" replace />, index: true },
            { path: 'profile', element: <UserProfilePage /> },
            { path: 'cards', element: <UserCardsPage /> },
            { path: 'list', element: <UserListPage /> },
            { path: 'new', element: <UserCreatePage /> },
            { path: ':name/edit', element: <UserEditPage /> },
            { path: 'account', element: <UserAccountPage /> },
          ],
        },
                // UPDATE 
        // Profile
        {
          path: 'profile',
          children: [
            { path: 'new', element: <UpdateProfilePage /> },
          ],
        },
        {
          path: 'about',
          children: [
            { path: 'new', element: <UpdateAboutUs /> },

          ],
        },
        {
          path: 'welcomeText',
          children: [
            { path: 'new', element: <UpdateWelcomeText /> },

          ],
        },
        {
          path: 'chooseUs',
          children: [
            { path: 'new', element: <UpdateChooseUs /> },

          ],
        },

          // Gallery
        {
          path: 'gallery',
          children: [
            { path: 'new', element: <UpdateGalleryPage /> },

          ],
        },
        {
          path: 'youTube',
          children: [
            { path: 'new', element: <UpdateYouTubeVideos /> },

          ],
        },
         // Advanced Profile
        {
          path: 'exclusiveDeals',
          children: [
            { path: 'new', element: <UpdateExclusiveDeals /> },
          ],
        },
        {
          path: 'hotelInfo',
          children: [
            { path: 'new', element: <UpdateHotelInfo /> },
          ],
        },
        {
          path: 'hotelFeature',
          children: [
            { path: 'new', element: <UpdateHotelfeature /> },
          ],
        },
        // setting
        {
          path: 'setting',
          children: [
            { path: 'new', element: <UpdateSetting /> },
          ],
        },
        {
          path: 'uploadSetting',
          children: [
            { path: 'new', element: <UpdateUploadSettingPage /> },
          ],
        },
        // cruds
        {
          path: 'billing',
          children: [
            { element: <Navigate to="/dashboard/billing/list" replace />, index: true },
            { path: 'profile', element: <UserProfilePage /> },
            { path: 'cards', element: <UserCardsPage /> },
            { path: 'list', element: <TopupListPage /> },
            { path: 'new', element: <TopupCreatePage /> },
            { path: ':name/edit', element: <UserEditPage /> },
            { path: 'account', element: <UserAccountPage /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            {
              element: (
                <Navigate to="/dashboard/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5" replace />
              ),
              index: true,
            },
            { path: 'list', element: <InvoiceListPage /> },
            { path: ':id', element: <InvoiceDetailsPage /> },
            { path: ':id/edit', element: <InvoiceEditPage /> },
            { path: 'new', element: <InvoiceCreatePage /> },
          ],
        },
        {
          path: 'testimonial',
          children: [
            { element: <Navigate to="/dashboard/testimonial/list" replace />, index: true },
            { path: 'profile', element: <UserProfilePage /> },
            { path: 'cards', element: <UserCardsPage /> },
            { path: 'list', element: <TestimonialListPage /> },
            { path: 'new', element: <TestimonialCreatePage /> },
            { path: ':name/edit', element: <TestimonialEditPage /> },
            { path: 'account', element: <UserAccountPage /> },
          ],
        },
        {
          path: 'activity',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'list', element: <ActivityListPage /> },
            { path: 'new', element: <ActivityCreatePage /> },
            { path: ':name/edit', element: <ActivityEditPage /> },
          ],
        },
        {
          path: 'service',
          children: [
            { element: <Navigate to="/dashboard/service/list" replace />, index: true },
            { path: 'list', element: <ServiceListPage /> },
            { path: 'new', element: <ServiceCreatePage /> },
            { path: ':name/edit', element: <ServiceEditPage /> },
          ],
        },
        {
          path: 'offer',
          children: [
            { element: <Navigate to="/dashboard/offer/list" replace />, index: true },
            { path: 'list', element: <OfferListPage /> },
            { path: 'new', element: <OfferCreatePage /> },
            { path: ':name/edit', element: <OfferEditPage /> },
          ],
        },
        {
          path: 'accommodation',
          children: [
            { element: <Navigate to="/dashboard/accommodation/list" replace />, index: true },
            { path: 'list', element: <AccommodationListPage /> },
            { path: 'new', element: <AccommodationCreatePage /> },
            { path: ':name/edit', element: <AccommodationEditPage /> },
          ],
        },

        // Mr.Traveller
        {
          path: 'places',
          children: [
            { element: <Navigate to="/dashboard/places/list" replace />, index: true },
            { path: 'list', element: <PlacesListPage /> },
            { path: 'new', element: <PlacesCreatePage /> },
            { path: ':name/edit', element: <PlacesEditPage /> },
          ],
        },
        {
          path: 'package',
          children: [
            { element: <Navigate to="/dashboard/places/list" replace />, index: true },
            { path: 'list', element: <PackageListPage /> },
            { path: 'new', element: <PackageCreatePage /> },
            { path: ':name/edit', element: <PackageEditPage /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPostsPage /> },
            { path: 'post/:title', element: <BlogPostPage /> },
            { path: 'new', element: <BlogNewPostPage /> },
          ],
        },
        { path: 'files-manager', element: <FileManagerPage /> },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <MailPage /> },
            { path: 'label/:customLabel/:mailId', element: <MailPage /> },
            { path: ':systemLabel', element: <MailPage /> },
            { path: ':systemLabel/:mailId', element: <MailPage /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <ChatPage />, index: true },
            { path: 'new', element: <ChatPage /> },
            { path: ':conversationKey', element: <ChatPage /> },
          ],
        },
        { path: 'calendar', element: <CalendarPage /> },
        { path: 'kanban', element: <KanbanPage /> },
        { path: 'permission-denied', element: <PermissionDeniedPage /> },
        { path: 'blank', element: <BlankPage /> },
      ],
    },

    // Main Routes
    {
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'about-us', element: <AboutPage /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <FaqsPage /> },
        // Demo Components
        {
          path: 'components',
          children: [
            { element: <ComponentsOverviewPage />, index: true },
            {
              path: 'foundation',
              children: [
                { element: <Navigate to="/components/foundation/colors" replace />, index: true },
                { path: 'colors', element: <FoundationColorsPage /> },
                { path: 'typography', element: <FoundationTypographyPage /> },
                { path: 'shadows', element: <FoundationShadowsPage /> },
                { path: 'grid', element: <FoundationGridPage /> },
                { path: 'icons', element: <FoundationIconsPage /> },
              ],
            },
            {
              path: 'mui',
              children: [
                { element: <Navigate to="/components/mui/accordion" replace />, index: true },
                { path: 'accordion', element: <MUIAccordionPage /> },
                { path: 'alert', element: <MUIAlertPage /> },
                { path: 'autocomplete', element: <MUIAutocompletePage /> },
                { path: 'avatar', element: <MUIAvatarPage /> },
                { path: 'badge', element: <MUIBadgePage /> },
                { path: 'breadcrumbs', element: <MUIBreadcrumbsPage /> },
                { path: 'buttons', element: <MUIButtonsPage /> },
                { path: 'checkbox', element: <MUICheckboxPage /> },
                { path: 'chip', element: <MUIChipPage /> },
                { path: 'data-grid', element: <MUIDataGridPage /> },
                { path: 'dialog', element: <MUIDialogPage /> },
                { path: 'list', element: <MUIListPage /> },
                { path: 'menu', element: <MUIMenuPage /> },
                { path: 'pagination', element: <MUIPaginationPage /> },
                { path: 'pickers', element: <MUIPickersPage /> },
                { path: 'popover', element: <MUIPopoverPage /> },
                { path: 'progress', element: <MUIProgressPage /> },
                { path: 'radio-button', element: <MUIRadioButtonsPage /> },
                { path: 'rating', element: <MUIRatingPage /> },
                { path: 'slider', element: <MUISliderPage /> },
                { path: 'stepper', element: <MUIStepperPage /> },
                { path: 'switch', element: <MUISwitchPage /> },
                { path: 'table', element: <MUITablePage /> },
                { path: 'tabs', element: <MUITabsPage /> },
                { path: 'textfield', element: <MUITextFieldPage /> },
                { path: 'timeline', element: <MUITimelinePage /> },
                { path: 'tooltip', element: <MUITooltipPage /> },
                { path: 'transfer-list', element: <MUITransferListPage /> },
                { path: 'tree-view', element: <MUITreesViewPage /> },
              ],
            },
            {
              path: 'extra',
              children: [
                { element: <Navigate to="/components/extra/animate" replace />, index: true },
                { path: 'animate', element: <DemoAnimatePage /> },
                { path: 'carousel', element: <DemoCarouselsPage /> },
                { path: 'chart', element: <DemoChartsPage /> },
                { path: 'copy-to-clipboard', element: <DemoCopyToClipboardPage /> },
                { path: 'editor', element: <DemoEditorPage /> },
                { path: 'form-validation', element: <DemoFormValidationPage /> },
                { path: 'image', element: <DemoImagePage /> },
                { path: 'label', element: <DemoLabelPage /> },
                { path: 'lightbox', element: <DemoLightboxPage /> },
                { path: 'map', element: <DemoMapPage /> },
                { path: 'mega-menu', element: <DemoMegaMenuPage /> },
                { path: 'multi-language', element: <DemoMultiLanguagePage /> },
                { path: 'navigation-bar', element: <DemoNavigationBarPage /> },
                { path: 'organization-chart', element: <DemoOrganizationalChartPage /> },
                { path: 'scroll', element: <DemoScrollbarPage /> },
                { path: 'snackbar', element: <DemoSnackbarPage /> },
                { path: 'text-max-line', element: <DemoTextMaxLinePage /> },
                { path: 'upload', element: <DemoUploadPage /> },
                { path: 'markdown', element: <DemoMarkdownPage /> },
              ],
            },
          ],
        },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { path: 'pricing', element: <PricingPage /> },
        { path: 'payment', element: <PaymentPage /> },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
