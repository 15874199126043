// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
// import Label from '../../../components/label';
// import Iconify from '../../../components/iconify';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'dashboard', path: PATH_DASHBOARD.general.dachboard, icon: ICONS.analytics },
      { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
      {
        title: 'billing',
        path: PATH_DASHBOARD.billing.root,
        icon: ICONS.banking,
        children: [
          { title: 'create', path: PATH_DASHBOARD.billing.new },
          { title: 'list', path: PATH_DASHBOARD.billing.list },
        ],
      },
      // { title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'file', path: PATH_DASHBOARD.general.file, icon: ICONS.file },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // USER
      {
        title: 'user',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          // { title: 'profile', path: PATH_DASHBOARD.user.profile },
          // { title: 'cards', path: PATH_DASHBOARD.user.cards },
          { title: 'create', path: PATH_DASHBOARD.user.new },
          { title: 'list', path: PATH_DASHBOARD.user.list },
          // { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
        ],
      },

      // UPDATE
      {
        title: 'profile',
        path: PATH_DASHBOARD.profile.new,
        icon: ICONS.menuItem,
        children: [
          { title: 'update profile', path: PATH_DASHBOARD.profile.new },
          { title: 'update about us', path: PATH_DASHBOARD.about.new },
          { title: 'update welcome text', path: PATH_DASHBOARD.welcomeText.new },
          { title: 'update choose us', path: PATH_DASHBOARD.chooseUs.new },
        ],
      },
      {
        title: 'gallery',
        path: PATH_DASHBOARD.gallery.new,
        icon: ICONS.blog,
        children: [
          { title: 'update gallery', path: PATH_DASHBOARD.gallery.new },
          { title: 'update youTube Videos', path: PATH_DASHBOARD.youTube.new },
        ],
      },
      {
        title: 'advanced Profile',
        path: PATH_DASHBOARD.exclusiveDeals.new,
        icon: ICONS.external,
        children: [
          { title: 'update exclusive deals', path: PATH_DASHBOARD.exclusiveDeals.new },
          { title: 'update hotel information', path: PATH_DASHBOARD.hotelInfo.new },
          { title: 'update hotel feature', path: PATH_DASHBOARD.hotelFeature.new },

        ],
      },

      // // INVOICE
      // {
      //   title: 'invoice',
      //   path: PATH_DASHBOARD.invoice.root,
      //   icon: ICONS.invoice,
      //   children: [
      //     // { title: 'list', path: PATH_DASHBOARD.invoice.list },
      //     { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
      //     // { title: 'create', path: PATH_DASHBOARD.invoice.new },
      //     // { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
      //   ],
      // },

      // TESTIMONIAL
      {
        title: 'testimonial',
        path: PATH_DASHBOARD.testimonial.root,
        icon: ICONS.chat,
        children: [
          { title: 'create', path: PATH_DASHBOARD.testimonial.new },
          { title: 'list', path: PATH_DASHBOARD.testimonial.list },
        ],
      },

      // ACTIVITY
      {
        title: 'activity',
        path: PATH_DASHBOARD.activity.root,
        icon: ICONS.blog,
        children: [
          { title: 'create', path: PATH_DASHBOARD.activity.new },
          { title: 'list', path: PATH_DASHBOARD.activity.list },
        ],
      },

      // // SERVICE
      // {
      //   title: 'service',
      //   path: PATH_DASHBOARD.service.root,
      //   icon: ICONS.booking,
      //   children: [
      //     { title: 'create', path: PATH_DASHBOARD.service.new },
      //     { title: 'list', path: PATH_DASHBOARD.service.list },
      //   ],
      // },

      // OFFER
      {
        title: 'offer',
        path: PATH_DASHBOARD.offer.root,
        icon: ICONS.kanban,
        children: [
          { title: 'create', path: PATH_DASHBOARD.offer.new },
          { title: 'list', path: PATH_DASHBOARD.offer.list },
        ],
      },

      // ACCOMMODATION
      {
        title: 'accommodation',
        path: PATH_DASHBOARD.accommodation.root,
        icon: ICONS.label,
        children: [
          { title: 'create', path: PATH_DASHBOARD.accommodation.new },
          { title: 'list', path: PATH_DASHBOARD.accommodation.list },
        ],
      },
      
      // SETTING
      {
        title: 'setting',
        path: PATH_DASHBOARD.setting.root,
        icon: ICONS.analytics,
        children: [
          { title: 'setting', path: PATH_DASHBOARD.setting.new },
          { title: 'Upload setting', path: PATH_DASHBOARD.uploadSetting.new, },
        ],
      },
      // BLOG
      // {
      //   title: 'blog',
      //   path: PATH_DASHBOARD.blog.root,
      //   icon: ICONS.blog,
      //   children: [
      //     { title: 'posts', path: PATH_DASHBOARD.blog.posts },
      //     { title: 'post', path: PATH_DASHBOARD.blog.demoView },
      //     { title: 'create', path: PATH_DASHBOARD.blog.new },
      //   ],
      // },
      // {
      //   title: 'File manager',
      //   path: PATH_DASHBOARD.fileManager,
      //   icon: ICONS.folder,
      // },
    ], 
   },

   { subheader: 'mr.traveller',
    items: [
      // PLACES
      {
        title: 'places',
        path: PATH_DASHBOARD.places.root,
        icon: ICONS.external,
        children: [
          { title: 'create', path: PATH_DASHBOARD.places.new },
          { title: 'list', path: PATH_DASHBOARD.places.list },
        ],
      },
      // PACKAGE
      {
        title: 'package',
        path: PATH_DASHBOARD.package.root,
        icon: ICONS.kanban,
        children: [
          { title: 'create', path: PATH_DASHBOARD.package.new },
          { title: 'list', path: PATH_DASHBOARD.package.list },
        ],
      },
    ]
  }

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: <Label color="error">+32</Label>,
  //     },
  //     {
  //       title: 'chat',
  //       path: PATH_DASHBOARD.chat.root,
  //       icon: ICONS.chat,
  //     },
  //     {
  //       title: 'calendar',
  //       path: PATH_DASHBOARD.calendar,
  //       icon: ICONS.calendar,
  //     },
  //     {
  //       title: 'kanban',
  //       path: PATH_DASHBOARD.kanban,
  //       icon: ICONS.kanban,
  //     },
  //   ],
  // },

  // DEMO MENU STATES
  // {
  //   subheader: 'Other cases',
  //   items: [
  //     {
  //       // default roles : All roles can see this entry.
  //       // roles: ['user'] Only users can see this item.
  //       // roles: ['admin'] Only admin can see this item.
  //       // roles: ['admin', 'manager'] Only admin/manager can see this item.
  //       // Reference from 'src/guards/RoleBasedGuard'.
  //       title: 'item_by_roles',
  //       path: PATH_DASHBOARD.permissionDenied,
  //       icon: ICONS.lock,
  //       roles: ['admin'],
  //       caption: 'only_admin_can_see_this_item',
  //     },
  //     {
  //       title: 'menu_level',
  //       path: '#/dashboard/menu_level',
  //       icon: ICONS.menuItem,
  //       children: [
  //         {
  //           title: 'menu_level_2a',
  //           path: '#/dashboard/menu_level/menu_level_2a',
  //         },
  //         {
  //           title: 'menu_level_2b',
  //           path: '#/dashboard/menu_level/menu_level_2b',
  //           children: [
  //             {
  //               title: 'menu_level_3a',
  //               path: '#/dashboard/menu_level/menu_level_2b/menu_level_3a',
  //             },
  //             {
  //               title: 'menu_level_3b',
  //               path: '#/dashboard/menu_level/menu_level_2b/menu_level_3b',
  //               children: [
  //                 {
  //                   title: 'menu_level_4a',
  //                   path: '#/dashboard/menu_level/menu_level_2b/menu_level_3b/menu_level_4a',
  //                 },
  //                 {
  //                   title: 'menu_level_4b',
  //                   path: '#/dashboard/menu_level/menu_level_2b/menu_level_3b/menu_level_4b',
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       title: 'item_disabled',
  //       path: '#disabled',
  //       icon: ICONS.disabled,
  //       disabled: true,
  //     },

  //     {
  //       title: 'item_label',
  //       path: '#label',
  //       icon: ICONS.label,
  //       info: (
  //         <Label color="info" startIcon={<Iconify icon="eva:email-fill" />}>
  //           NEW
  //         </Label>
  //       ),
  //     },
  //     {
  //       title: 'item_caption',
  //       path: '#caption',
  //       icon: ICONS.menuItem,
  //       caption:
  //         'Quisque malesuada placerat nisl. In hac habitasse platea dictumst. Cras id dui. Pellentesque commodo eros a enim. Morbi mollis tellus ac sapien.',
  //     },
  //     {
  //       title: 'item_external_link',
  //       path: 'https://www.google.com/',
  //       icon: ICONS.external,
  //     },
  //     {
  //       title: 'blank',
  //       path: PATH_DASHBOARD.blank,
  //       icon: ICONS.blank,
  //     },
  //   ],
  // },
];

export default navConfig;
